Lyte.Component.register("crux-parent-form-component", {
_template:"<template tag-name=\"crux-parent-form-component\"> <lyte-yield yield-name=\"cruxParentFormComponent\"></lyte-yield> </template>",
_dynamicNodes : [{"type":"insertYield","position":[1]}],
_observedAttributes :["widthData","labelElmArr","labelElmTextArr","labelElmIconArr"], //no i18n
	data : function(){
		return {
			widthData : Lyte.attr('array',{default:[]}),//no i18n
			labelElmArr : Lyte.attr('array',{default:[]}),//no i18n
			labelElmTextArr : Lyte.attr('array',{default:[]}),//no i18n
			labelElmIconArr : Lyte.attr('array',{default:[]})//no i18n
		}
	},
	didConnect : function(){
		this.updateFormLabelWidth();
	},
	createSpanElement: function(){
		var rootElementStyle = getComputedStyle(document.querySelector(':root'));
		var canvasElem = document.createElement("canvas");
		var canvasElemCxt = canvasElem.getContext("2d");//no i18n
		canvasElemCxt.class = "dummyLabelSpan f15" // NO I18N
		canvasElemCxt.font = "1.4rem "+rootElementStyle.getPropertyValue('--crux-primary-font')

		return canvasElemCxt;
	},
	removeSpanElement: function(spanElm){
		spanElm.canvas.remove();
	},

	updateFormLabelWidth : function(){
		var currentForm = this.$node;
		var _this = this;

		var currentFormRows = $L(currentForm).find(".crmFormComponentRow, .cruxFormComponentRow");
		this.setData({'widthData':[]}); //no i18n
		var widthData = this.getData('widthData'); //no i18n

		var spanElm = this.createSpanElement();
		var labelArr = [], labelIconArr = [], labelTextArr = [];
		currentFormRows.each(function(i, elm){
			var lyteLabel = $L(elm).find(".lyteLabel, .cxElementLabel");
			if(lyteLabel.length === 0){
				return;
			}
			labelArr.push(lyteLabel);

			var cxInfoIconWt = 0;
			if(lyteLabel.find(".cxInfoIcon").length > 0){
				cxInfoIconWt = 20;
			}
			labelIconArr.push(cxInfoIconWt);
			// labelTextArr.push(lyteLabel.html());
			labelTextArr.push(lyteLabel.text()); /* When Info icon is added in label, html() takes whole html of icon. so changed to text */
			})
		this.setData("labelElmArr", labelArr);
		this.setData("labelElmIconArr", labelIconArr);
		this.setData("labelElmTextArr", labelTextArr);
		currentFormRows.each(function(i, elm){
			var textWidth = Math.ceil(spanElm.measureText(labelTextArr[i]).width) + 18 + labelIconArr[i];
			widthData[widthData.length] = textWidth;
			_this.setData('widthData',widthData);	//no i18n
		});
		if(widthData.length > 0){
			var maxWidth = Math.max.apply(null, widthData);
			if(maxWidth > 200){
				maxWidth = 200;
			}
			$(currentFormRows).find(">.lyteLabel,>.cxElementLabel").css({"width": maxWidth + "px"});	//eslint-disable-line zstandard/no-jQuery-casting
			var currentFormWidth = maxWidth + 395;	//375 + 5
			$L(currentForm).css({minWidth: currentFormWidth+"px", display: 'block'});	//no i18n
		}
		this.removeSpanElement(spanElm);
	}
});
